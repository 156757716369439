import React, { createContext, useState } from "react";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { SYNMIO_SCRIPT_ID } from "./constants";

// Create a Context
const Context = createContext();

// Create a provider component
const Provider = ({ children }) => {
  const [dataSet, setDataSet] = useState(null);
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === "/") {
      setDataSet(null);
      document.getElementById(SYNMIO_SCRIPT_ID)?.remove();
    }
  }, [location]);

  return (
    <Context.Provider value={{ dataSet, setDataSet }}>
      {children}
    </Context.Provider>
  );
};

export { Context, Provider };
