// string to currency number
export function formatCurrency(value) {
  const number = parseFloat(value.replace(/[^0-9.-]+/g, ""));

  if (isNaN(number)) {
    return "0.00";
  }

  return number.toFixed(2);
}
