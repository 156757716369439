import React, { useEffect, useState } from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import { useLocation } from "react-router-dom";
import { SYNMIO_SCRIPT_ID } from "../constants";
import { useParams } from "react-router-dom";
import { formatCurrency } from "../utils/helpers";

const ProductDetails = () => {
  const [product, setProduct] = useState(null);
  const { productId } = useParams();
  const query = new URLSearchParams(useLocation().search);
  const clientId = query.get("client_id");
  const dataKey = query.get("data_model");

  useEffect(() => {
    if (document.getElementById(SYNMIO_SCRIPT_ID)) {
      return;
    }

    fetch(
      "https://fejpi7v1d5.execute-api.eu-west-2.amazonaws.com/dev/fetch_product",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          product_id: productId,
          client_id: clientId,
          data_key: dataKey,
        }),
      },
    )
      .then((response) => response.json())
      .then((data) => {
        if (document.getElementById(SYNMIO_SCRIPT_ID)) {
          return;
        }

        setProduct(data);
        const script = document.createElement("script");
        script.src =
          "https://distribution-assets.s3.eu-west-2.amazonaws.com/dist-dev.js";
        script.id = SYNMIO_SCRIPT_ID;
        script.dataset.clientId = clientId;
        script.dataset.model = dataKey;
        script.dataset.primaryColor = "#000000";
        script.dataset.secondaryColor = "#cacbcb";
        script.dataset.tertiaryColor = "#cfa132";
        script.dataset.ctaColor = "#5cac17";
        script.dataset.font = "Lato";
        script.dataset.type = "chat";
        script.dataset.insertAfter = "product-description";
        script.dataset.productId = productId;
        document.body.appendChild(script);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);

  return (
    <>
      <Navbar />
      {product && (
        <section className="product-detail">
          <div className="product-detail-container">
            <div className="product-image">
              <img src={product.image} alt="Product Detail" />
            </div>
            <div className="product-info">
              <h2>{product.name}</h2>
              <p className="price">£{formatCurrency(product.price)}</p>
              <p id="product-description" className="description">
                This is a detailed description of the product. It highlights the
                features, benefits, and specifications of the product.
              </p>
              <button className="btn">Add to Cart</button>
            </div>
          </div>
        </section>
      )}
      <Footer />
    </>
  );
};

export default ProductDetails;
