import React, { useContext, useEffect } from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import { SYNMIO_SCRIPT_ID } from "../constants";
import { Context } from "../Context";

const Storefront = ({ clientId, model }) => {
  const { dataSet } = useContext(Context);

  useEffect(() => {
    if (document.getElementById(SYNMIO_SCRIPT_ID)) {
      return;
    }

    const script = document.createElement("script");
    script.src =
      "https://distribution-assets.s3.eu-west-2.amazonaws.com/dist-dev.js";
    script.id = SYNMIO_SCRIPT_ID;
    script.dataset.clientId = dataSet ? dataSet.clientId : "AsosDev";
    script.dataset.model = dataSet ? dataSet.data_key : "asos_data-1500";
    script.dataset.primaryColor = "#000000";
    script.dataset.secondaryColor = "#cacbcb";
    script.dataset.tertiaryColor = "#cfa132";
    script.dataset.ctaColor = "#5cac17";
    script.dataset.font = "Lato";
    script.dataset.type = "recommender";
    script.dataset.insertAfter = "search-input";
    script.dataset.demoMode = "true";
    script.dataset.buttonType = "icon";
    document.body.appendChild(script);
  }, [dataSet]);

  return (
    <>
      <Navbar />
      <section className="hero">
        <div className="hero-text">
          <h2>Welcome to the Synmio Store</h2>
          <p>Best products at unbeatable prices</p>
          <a href="#" className="btn">
            Shop Now
          </a>
        </div>
      </section>

      <section className="products">
        <h2>Featured Products</h2>
        <div className="product-grid">
          <div className="product-card">
            <img
              src="https://developers.elementor.com/docs/assets/img/elementor-placeholder-image.png"
              alt="Product 1"
            />
            <h3>Product 1</h3>
            <p>$19.99</p>
            <button>Add to Cart</button>
          </div>
          <div className="product-card">
            <img
              src="https://developers.elementor.com/docs/assets/img/elementor-placeholder-image.png"
              alt="Product 2"
            />
            <h3>Product 2</h3>
            <p>$29.99</p>
            <button>Add to Cart</button>
          </div>
          <div className="product-card">
            <img
              src="https://developers.elementor.com/docs/assets/img/elementor-placeholder-image.png"
              alt="Product 3"
            />
            <h3>Product 3</h3>
            <p>$39.99</p>
            <button>Add to Cart</button>
          </div>
          <div className="product-card">
            <img
              src="https://developers.elementor.com/docs/assets/img/elementor-placeholder-image.png"
              alt="Product 4"
            />
            <h3>Product 4</h3>
            <p>$49.99</p>
            <button>Add to Cart</button>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default Storefront;
