import { useContext, useEffect, useState } from "react";
import { Context } from "../Context";
import { useNavigate } from "react-router-dom";

export default function DataSetPicker() {
  const [selectedItem, setSelectedItem] = useState(null);
  const [dataSets, setDataSets] = useState([]);
  const { setDataSet } = useContext(Context);
  const navigate = useNavigate();

  useEffect(() => {
    fetch(
      "https://fejpi7v1d5.execute-api.eu-west-2.amazonaws.com/dev/data_sets",
      {
        method: "POST",
      },
    ).then(async (response) => {
      let data = await response.json();
      const _dataSets = data.data_sets;
      setSelectedItem(_dataSets[0].clientId);
      setDataSets(_dataSets);
    });
  }, []);

  const handleSelectChange = (event) => {
    setSelectedItem(event.target.value);
  };

  const handleButtonClick = () => {
    setDataSet(dataSets.find((item) => item.clientId === selectedItem));
    navigate("/store");
  };

  if (dataSets.length === 0) {
    return <div>Loading...</div>;
  }

  return (
    <div className="data-set-wrapper">
      <div className="container">
        <h1>Choose a dataset</h1>
        <select value={selectedItem} onChange={handleSelectChange}>
          {dataSets.map((item, index) => (
            <option key={index} value={item.clientId}>
              {item.name}
            </option>
          ))}
        </select>
        <button onClick={handleButtonClick}>Confirm</button>
      </div>
    </div>
  );
}
