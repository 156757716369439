import React from "react";
import DataSetPicker from "./components/DataSetPicker";
import Storefront from "./components/Storefront";
import ProductDetails from "./components/ProductDetails";
import { Route, Routes, BrowserRouter as Router } from "react-router-dom";
import "./App.css";
import { Provider } from "./Context";

const App = () => {
  return (
    <Router>
      <Provider>
        <div className="App">
          <Routes>
            <Route path="/" element={<DataSetPicker />} />
            <Route path="/store" element={<Storefront />} />
            <Route path="/product/:productId" element={<ProductDetails />} />
          </Routes>
        </div>
      </Provider>
    </Router>
  );
};

export default App;
